import React, {useState} from "react";
import axios from "axios";
import { useForm } from "react-hook-form";

function Contact() {
	
	const [mailSent, setmailSent] = useState(false);
	const [error, setError] = useState(null);
	
	
	
	const {
	register,
	watch,
	handleSubmit,
	reset,
	formState: { errors },
	} = useForm();
	
	//const formData = watch();
	
	const formSubmit = async (data) => {
				
		axios({
		  method: "post",
		  url: `${process.env.REACT_APP_API}`,
		  headers: { "content-type": "application/json" },
		  data: data
		})
		  .then(result => {
			if (result.data.sent) {
			  setmailSent(result.data.sent)
			  setError(false)
			  reset({ name: '', email:'', comment:''});
			} else {
			  setError(true)
			}
		  })
		  .catch(error => setError( error.message ));
		
	}

	

  return (
<>
<div className="sub-banner">
<section className="banner-section">
    <div className="container">
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="banner_content aos-init aos-animate" data-aos="fade-up">
                    <div className="box">
                        <span className="mb-0">Home</span>
							<figure className="mb-0">
								<img src={require("../assets/images/subbanner_arrow.png")} className="img-fluid" alt="arrow" />
							</figure>
							<span className="mb-0 box_span">Contact</span>
                    </div>
                    <h1>Contact Us</h1>
                    <p className="mb-0 text-white">Modi tempora sed quia non numquam eius incidunt ut labore et magnam aliquam quaerat voluptatem nostrum.</p>
                </div>
            </div>
        </div>
        <figure className="mb-0 bannersidecircle1 aos-init aos-animate" data-aos="fade-left">
            <img src={require("../assets/images/banner-sidecircle1.png")} alt="" />
        </figure>
        <figure className="mb-0 bannersidecircle2 aos-init" data-aos="fade-left">
            <img src={require("../assets/images/banner-sidecircle2.png")} alt="" />
        </figure>
    </div>    
</section>
</div>

{/*-- Message --*/}

<section className="message-section about-section">
    <div className="container">
        <figure className="mb-0 price-circle">
            <img className="img-fluid" src={require("../assets/images/price-circle.png")}  alt="" />
        </figure>
        <div className="row position-relative">
            <figure className="mb-0 price-sidecircle">
               <img className="img-fluid" src={require("../assets/images/price-sidecircle.png")} alt="" />
            </figure>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="manage_wrapper">
                    <figure className="mb-0 manage-phoneback">
                        <img src={require("../assets/images/manage-phoneback.png")} alt="" />
                    </figure>
                    <figure className="mb-0 manage-phone2">
                        <img className="img-fluid" src={require("../assets/images/manage-phone2.png")} alt="" />
                    </figure>
                    <figure className="mb-0 manage-phone1">
                        <img className="img-fluid" src={require("../assets/images/manage-phone1.png")} alt="" />
                    </figure>
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="manage_content aos-init aos-animate" data-aos="fade-up"> 
                    <div className="message_content">
                        <h2>Send Us a Message</h2>
                        <form id="contactpage" method="POST" onSubmit={handleSubmit(formSubmit)} action="./contact-form.php">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group mb-0">    
										<input type="text" 
										className={`form_style  ${errors.name && ('element-error-txt')}`} 
										placeholder="Your Name" 										
										{...register("name", { required: true, minLength: 2 })}
										/> 
									</div>								
                                </div>
								
                                <div className="col-12">
                                    <div className="form-group mb-0">
										<input type="email" 
                                            className={`form_style  ${errors.email && ('element-error-txt')}`} 
											placeholder="Your Email"											
											{...register("email", { required: 'is invalid',
												pattern: {
													value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
															  message: 'is invalid ',
													},
												})} 
										/>
                                    </div>
                                </div>
								
                                <div className="col-lg-12">
                                    <div className=" form-group mb-0">    
                                    <textarea 
                                    className={`form_style  ${errors.comment && ('element-error-txt')}`}  
                                    placeholder="Message" rows="3" 
									{...register("comment", { required: true })}
									></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="manage-button text-center">
                                <button type="submit" className="submit_now">Submit Now</button>
                            </div>
							
							 <div>
								  {mailSent && <div className="sucsess">Thank you for connecting me</div>}
								  {error && <div className="error">Sorry we have some Problems</div>}
							 </div>
							
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

{/*-- Contact --*/}

<section className="feature-aboutsection contact-section">
    <div className="container">
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="feature_aboutheading">
                    <h2>Our Contact Information</h2>
                </div>
            </div>
        </div>

        <div className="feature-block">
            <div className="row aos-init aos-animate" data-aos="fade-up">
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                    <div className="feature_aboutcontent box hover1">
						<div className="icon-size1">
							 <figure className="mb-0 contact-addressicon">
								<img src={require("../assets/images/contact-addressicon.png")} alt="" className="img-fluid" />
                            </figure>
                        </div>
                        <h5>Address</h5>
                        <p className="mb-0 text-size-18">121 King Street, Near City Area New York 10001 USA</p>
					</div>
                </div>
				
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                    <div className="feature_aboutcontent box hover2">
                        <div className="icon-size1 icon-size2">
                            <figure className="mb-0 contact-phoneicon">
                                <img src={require("../assets/images/contact-phoneicon.png")} alt="" className="img-fluid" />
                            </figure>
                        </div>
                        <h5>Phone</h5>
                        <a href="tel:+919212341234" className="mb-0 text-decoration-none text-size-18">+91 92 1234 1234</a>
                        <a href="tel:+5193453782" className="mb-0 text-decoration-none text-size-18">+51 9345 3782</a>
                    </div>
                </div>	

                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                    <div className="feature_aboutcontent box hover3">
                        <div className="icon-size1 icon-size3">
                            <figure className="mb-0 contact-emailicon">
                                <img src={require("../assets/images/contact-emailicon.png")} alt="" className="img-fluid" />
                            </figure>
                        </div>
                        <h5>Email</h5>
                        <a href="mailto:info@cloud.com" className="mb-0 text-decoration-none text-size-18">info@example.com</a>
                        <a href="mailto:support@cloud.com" className="mb-0 text-decoration-none text-size-18">support@example.com</a>
                    </div>
                </div>				
		
		
		
			</div>
		</div>	
	</div>
</section>

</>

  );	
	
}

export default Contact;
