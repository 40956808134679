import React, { useState} from 'react';
import { NavLink } from "react-router-dom";



export default function Header({routes }) {
	
	
const [isNavCollapsed, setIsNavCollapsed] = useState(true);

const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
	
  return (

   <div className="banner_outer1">
	  <header>
	    <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
		  <NavLink className="navbar-brand"  to="/home" >
	
				<figure className="mb-0">
					<img src={require("../assets/images/banner-logo.png")}   alt="log" className="img-fluid" />
				</figure>
	
		</NavLink>	
			
			    <button className={`${isNavCollapsed ? 'collapsed' : ''} navbar-toggler`}					
				
				type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={!isNavCollapsed ? true : false}  aria-label="Toggle navigation" onClick={handleNavCollapse}>
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
                </button>
				


                <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
					
					
					{routes.map((prop, key) => {
						if (!prop.redirect)
						  return (
							  <li key={key} className="nav-item">				  
								<NavLink className={prop.className}  to={prop.path} >{prop.name}</NavLink>
							  </li>
						  );
						return null;
					  })}
								

                    </ul>
                </div>	
		  </nav>
		</div>  
	  </header>
	  <figure className="mb-0 bannersidelayer">
			<img src={require("../assets/images/banner-sidelayer.png")} alt="" />
	  </figure> 
    </div> 
 
 
  )
}
