import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

function Features() {


  return (
<>
<div className="sub-banner">
<section className="banner-section">
    <div className="container">
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="banner_content" data-aos="fade-up">
                    <div className="box">
                        <span className="mb-0">Home</span>
						<figure className="mb-0">
							<img src={require("../assets/images/subbanner_arrow.png")} alt="" className="img-fluid" />
						</figure><span className="mb-0 box_span">Features</span>
                    </div>
                    <h1>Our Features</h1>
                    <p className="mb-0 text-white">Tempora incidunt sed quia non numquam eius modi ut labore et magnam aliquam quaerat voluptatem nostrum.</p>
                </div>
            </div>
        </div>
        <figure className="mb-0 bannersidecircle1" data-aos="fade-left">
            <img src={require("../assets/images/banner-sidecircle1.png")} alt="" />
        </figure>
        <figure className="mb-0 bannersidecircle2" data-aos="fade-left">
            <img src={require("../assets/images/banner-sidecircle2.png")} alt="" />
        </figure>
    </div>    
</section>
</div>



<section className="feature-aboutsection about-section" id="about-app">
    <div className="container">
        <figure className="mb-0 price-circle">
            <img className="img-fluid" src={require("../assets/images/price-circle.png")} alt="" />
        </figure>
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="feature_aboutheading">
                    <h2>Features About App</h2>
                </div>
            </div>
        </div>
        <div className="feature-block">
            <div className="row" data-aos="fade-up">
			
                 <figure className="mb-0 price-sidecircle">
                    <img className="img-fluid" src={require("../assets/images/price-sidecircle.png")}  alt="price-sidecircle" />
                </figure>
				
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                    <div className="feature_aboutcontent box hover1">
                        <div className="icon-size1">
                            <figure className="mb-0 feature-friendicon">
                                <img src={require("../assets/images/feature-friendicon.png")} alt="" className="img-fluid" />
                            </figure>
                        </div>
                        <h5>Friendly Interface</h5>
                        <p className="mb-0 text-size-18">Occaecat duis aute irure dolor in reprehen velit esse cillum dolore eu fugia excepteur sint cuidata officia deserunt mollit.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                    <div className="feature_aboutcontent box hover2">
                        <div className="icon-size1 icon-size2">
                            <figure className="mb-0 feature-syncicon">
                                <img src={require("../assets/images/feature-syncicon.png")} alt="" className="img-fluid" />
                            </figure>
                        </div>
                        <h5>Super Fast Sync</h5>
                        <p className="mb-0 text-size-18">Occaecat duis aute irure dolor in reprehen velit esse cillum dolore eu fugia excepteur sint cuidata officia deserunt mollit.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                    <div className="feature_aboutcontent box hover3">
                        <div className="icon-size1 icon-size3">
                            <figure className="mb-0 feature-powericon">
                                <img src={require("../assets/images/feature-powericon.png")} alt="" className="img-fluid" />
                            </figure>
                        </div>
                        <h5>Powerful Options</h5>
                        <p className="mb-0 text-size-18">Occaecat duis aute irure dolor in reprehen velit esse cillum dolore eu fugia excepteur sint cuidata officia deserunt mollit.</p>
                    </div>
                </div>
            </div>
            <div className="row" data-aos="fade-up">
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                    <div className="feature_aboutcontent box hover4">
                        <div className="icon-size1 icon-size4">
                            <figure className="mb-0 feature-effecticon">
                                <img src={require("../assets/images/feature-effecticon.png")} alt="" className="img-fluid" />
                            </figure>
                        </div>
                        <h5>Image Effects</h5>
                        <p className="mb-0 text-size-18">Occaecat duis aute irure dolor in reprehen velit esse cillum dolore eu fugia excepteur sint cuidata officia deserunt mollit.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                    <div className="feature_aboutcontent box hover5">
                        <div className="icon-size1 icon-size5">
                            <figure className="mb-0 feature-accessicon">
                                <img src={require("../assets/images/feature-accessicon.png")} alt="" className="img-fluid" />
                            </figure>
                        </div>
                        <h5>Direct Access</h5>
                        <p className="mb-0 text-size-18">Occaecat duis aute irure dolor in reprehen velit esse cillum dolore eu fugia excepteur sint cuidata officia deserunt mollit.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                    <div className="feature_aboutcontent box hover6">
                        <div className="icon-size1 icon-size6">
                            <figure className="mb-0 feature-analyticicon.">
                                <img src={require("../assets/images/feature-analyticicon.png")} alt="" className="img-fluid" />
                            </figure>
                        </div>
                        <h5>Analytics</h5>
                        <p className="mb-0 text-size-18">Occaecat duis aute irure dolor in reprehen velit esse cillum dolore eu fugia excepteur sint cuidata officia deserunt mollit.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


{/*-- Features --*/}

<section className="basic-feature feature-basicfeature" >
    <div className="container">
        <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="feature_wrapperleft">
                    <figure className="mb-0 feature-circle">
                        <img className="img-fluid" src={require("../assets/images/feature-circle.png")} alt="" />
                    </figure>
                    <figure className="mb-0 featurephoneback">
                        <img src={require("../assets/images/featurephoneback-img.png")} alt="" />
                    </figure>
                    <figure className="mb-0 featurephone">
                        <img className="img-fluid" src={require("../assets/images/featurephone-img.png")} alt="" />
                    </figure>
                    <figure className="mb-0 featurephone-over">
                        <img className="img-fluid" src={require("../assets/images/featurephone-over.png")} alt="" />
                    </figure>
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="feature_wrapperright">
                    <div className="feature_content">
                        <h4>Our Basic Features</h4>
                        <h2>Some of The Best Features</h2>
                    </div>
                    <div className="feature_lowercontent" data-aos="fade-up">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="feature-box">
                                    <figure className="feature-marketicon">
                                        <img src={require("../assets/images/feature-marketicon.png")} alt="" className="img-fluid" />
                                    </figure> 
                                    <h6>Marketing</h6>
                                    <p className="text-size-18 mb-0">We denounce with righteous indignation and dislike men who are so beguiled.</p>
                                </div>   
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="feature-design">
                                    <div className="feature-box">
                                        <figure className="feature-graphicon feature-box-2">
                                            <img src={require("../assets/images/feature-graphicon.png")}  alt="" className="img-fluid" />
                                        </figure>
                                        <h6>Graphic Design</h6>
                                        <p className="text-size-18 mb-0">These cases are perfectly simple distinguish and easy to In a free hour.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="feature-downcontent">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="feature-box">
                                        <figure className="feature-graphicon feature-box-3">
                                            <img src={require("../assets/images/feature-supporticon.png")} alt="" className="img-fluid" />
                                        </figure>
                                        <h6>Support</h6>
                                        <p className="text-size-18 mb-0">Ruis aute irure dolor in re derit in voluptate velit esse fugiat nulla pariatur.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="feature-design feature-guide">
                                        <div className="feature-box">
                                            <figure className="feature-guideicon feature-box-4">
                                                <img src={require("../assets/images/feature-guideicon.png")} alt="" className="img-fluid" />
                                            </figure>
                                            <h6>User Guide</h6>
                                            <p className="text-size-18 mb-0">Auis aute irure dolor in re derit in voluptate velit esse fugiat nulla pariatur.</p> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

{/*-- feature partner --*/}


<div className="partner-section feature-partner"> 
    <div className="container">
        <div className="partner_box" data-aos="fade-up">
            <ul className="mb-0 list-unstyled">
                <li>
                    <figure className="mb-0 partner1">
                        <img className="img-fluid" src={require("../assets/images/partner1.png")} alt="" />
                    </figure>
                </li>
                <li>
                    <figure className="mb-0 partner1 partner2">
                        <img className="img-fluid" src={require("../assets/images/partner2.png")} alt="" />
                    </figure>
                </li>
                <li>
                    <figure className="mb-0 partner1 partner3">
                        <img className="img-fluid" src={require("../assets/images/partner3.png")} alt="" />
                    </figure>
                </li>
                <li>
                    <figure className="mb-0 partner1 partner4">
                        <img className="img-fluid" src={require("../assets/images/partner4.png")} alt="" />
                    </figure>
                </li>
                <li>
                    <figure className="mb-0 partner1 partner5">
                        <img className="img-fluid" src={require("../assets/images/partner5.png")} alt="" />
                    </figure>
                </li>
            </ul>
        </div>
    </div>
</div>



{/*-- FAQ's --*/}

<section className="accordian-section">
    <div className="container">
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="accordian_content">
                    <h4>Ask Something?</h4>
                    <h2>Frequently Asked Questions</h2>
                </div>
            </div>
        </div>
        <div className="row position-relative" data-aos="fade-up">
            <div className="col-lg-1 col-md-1 col-sm-1 d-lg-block d-none"></div>
            <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12">
			

            <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Itaque earum Maiores alias conseuatur aut peruerendis?
              </Accordion.Header>
              <Accordion.Body>
                There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which do not look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.				
              </Accordion.Body>
            </Accordion.Item>
      
            <Accordion.Item eventKey="1">
              <Accordion.Header>Alias conseuatur Dolor renellendus temporibus autem zuibusdam?
              </Accordion.Header>
              <Accordion.Body>
                consectetur adipiscing elit, Lorem ipsum dolor sit amet, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
      
            <Accordion.Item eventKey="2">
              <Accordion.Header>Temporibus autem officia deserunt mollitia animi est laborum?
              </Accordion.Header>
              <Accordion.Body>
              Dolore magnam incidunt ut labore et aliquam quaerat voluptatem ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi.
              </Accordion.Body>
            </Accordion.Item>
            
            <Accordion.Item eventKey="3">
              <Accordion.Header>Animi est reiciendis voluptatibus maiores alias consequatur?
              </Accordion.Header>
              <Accordion.Body>
              Aliquam quaerat voluptatem incidunt ut labore et dolore magnam ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi.
              </Accordion.Body>
            </Accordion.Item>
      
            
      </Accordion>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-1 d-lg-block d-none"></div>
            <figure className="mb-0 faq_shape">
                <img src={require("../assets/images/faq_icon.png")} alt="" className="img-fluid" />
            </figure>
        </div>
    </div>
</section>

</>

  );	
	
}

export default Features;
