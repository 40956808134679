import React from 'react';

function About() {


    return (
        <>
            <div className="sub-banner">
                <section className="banner-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="banner_content aos-init aos-animate" data-aos="fade-up">
                                    <div className="box">
                                        <span className="mb-0">Home</span>
                                        <figure className="mb-0">
                                            <img src={require("../assets/images/subbanner_arrow.png")} alt="" className="img-fluid" />
                                        </figure>
                                        <span className="mb-0 box_span">About</span>
                                    </div>
                                    <h1>About Us</h1>
                                    <p className="mb-0 text-white">Modi tempora sed quia non numquam eius incidunt ut labore et magnam aliquam quaerat voluptatem nostrum.</p>
                                </div>
                            </div>
                        </div>
                        <figure className="mb-0 bannersidecircle1 aos-init aos-animate" data-aos="fade-left">
                            <img src={require("../assets/images/banner-sidecircle1.png")} alt="" />
                        </figure>
                        <figure className="mb-0 bannersidecircle2 aos-init aos-animate" data-aos="fade-left">
                            <img src={require("../assets/images/banner-sidecircle2.png")} alt="" />
                        </figure>
                    </div>
                </section>
            </div>

            {/*-- About --*/}

            <section className="about-section">
                <div className="container">
                    <figure className="mb-0 price-circle">
                        <img className="img-fluid" src={require("../assets/images/price-circle.png")} alt="" />
                    </figure>
                    <div className="row position-relative aos-init aos-animate" data-aos="fade-up">
                        <figure className="mb-0 price-sidecircle">
                            <img className="img-fluid" src={require("../assets/images/price-sidecircle.png")} alt="" />
                        </figure>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="about_content hover1">
                                <div className="icon-size1">
                                    <figure className="mb-0  about-historyicon">
                                        <img src={require("../assets/images/about-historyicon.png")} alt="" className="img-fluid" />
                                    </figure>
                                </div>
                                <h3>Our History</h3>
                                <p className="mb-0">Volutae velit duis aute irure dolor in reprehenderit in esse cillum dolore eu fugia nulla pariatur excepteur sint occaecat cupidatat non sunt in officia deserunt mollit anim id est laborum omnis voluptas assumenda est.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="about_content about_content1 hover2">
                                <div className="icon-size1 icon-size2">
                                    <figure className="mb-0  about-visionicon">
                                        <img src={require("../assets/images/about-visionicon.png")} alt="" className="img-fluid" />
                                    </figure>
                                </div>
                                <h3>Our Vision</h3>
                                <p className="mb-0">Reprehenderit in volutae auis aute irure dolor in velit esse cillum dolore eu fugia nulla pariatur excepteur sint occaecat cupidatat non sunt in officia deserunt mollit anim id est laborum omnis voluptas assumenda est.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*-- Count --*/}

            <section className="count-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="count_content aos-init aos-animate" data-aos="fade-up">
                                <h2>Who We Are?</h2>
                                <p>Voluptate velit zuis aute irure dolor in reprehenderit in esse cillum dolore eu fugia nulla pariatur excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum autem.</p>
                                <div className="count-imagewrapper">
                                    <a className="image_apple" href="https://www.apple.com/app-store/">
                                        <figure className="mb-0 offer-apple">
                                            <img className="img-fluid" src={require("../assets/images/bannerapple-img1.png")} alt="" />
                                        </figure>
                                    </a>
                                    <a className="image_google" href="https://play.google.com/store/games">
                                        <figure className="mb-0 offer-google">
                                            <img className="img-fluid" src={require("../assets/images/bannergoogle-img2.png")} alt="" />
                                        </figure>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="count-rightcontent">
                                <div className="number1">
                                    <span className="value1 counter">1210</span>
                                    <sup className="mb-0 plus1">+</sup>
                                    <span className="mb-0 text1">Clients</span>
                                </div>
                                <div className="number1 number2">
                                    <span className="value1 value2 counter">2110</span>
                                    <sup className="mb-0 plus1 plus2">+</sup>
                                    <span className="mb-0 text1 text2">Beneficiaries</span>
                                </div>
                                <div className="number1 number3">
                                    <span className="value1 value3 counter">1512</span>
                                    <sup className="mb-0 plus1 plus3">+</sup>
                                    <span className="mb-0 text1 text3">Reviews</span>
                                </div>
                                <div className="number1 number4">
                                    <span className="value1 value4 counter">1282</span>
                                    <sup className="mb-0 plus1 plus4">+</sup>
                                    <span className="mb-0 text1 text4">Downloads</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*-- Team --*/}

            <section className="team-section" id="our-team">
                <div className="teams-background">
                    <div className="container">
                        <figure className="mb-0">
                            <img src={require("../assets/images/teamt-sidelayer.png")} alt="" className="img-fluid" />
                        </figure>
                    </div>
                </div>
                <div className="our-teams-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="team_content">
                                    <h2 className="text-white">Our Team Members</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row aos-init aos-animate" data-aos="fade-up">
                            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                <div className="team-box">
                                    <div className="upper-part">
                                        <figure className="mb-0">
                                            <img src={require("../assets/images/team-image1.png")} alt="" className="img-fluid" />
                                        </figure>
                                    </div>
                                    <div className="lower-part">
                                        <h3>Mayank Parmar</h3>
                                        <p>CEO &amp; Founder</p>
                                        <ul className="list-unstyled mb-0">
                                            <li>
                                                <a href="/#"><svg xmlns="http://www.w3.org/2000/svg" className='social-icon' fill="#151515" width="20" height="20" viewBox="0 0 64 64">
                                                    <path d="M32,6C17.642,6,6,17.642,6,32c0,13.035,9.603,23.799,22.113,25.679V38.89H21.68v-6.834h6.433v-4.548	c0-7.529,3.668-10.833,9.926-10.833c2.996,0,4.583,0.223,5.332,0.323v5.965h-4.268c-2.656,0-3.584,2.52-3.584,5.358v3.735h7.785	l-1.055,6.834h-6.73v18.843C48.209,56.013,58,45.163,58,32C58,17.642,46.359,6,32,6z"></path>
                                                </svg></a>
                                            </li>
                                            <li>
                                                <a href="/#"><svg xmlns="http://www.w3.org/2000/svg" className='social-icon' fill="#151515" width="20" height="20" viewBox="0 0 48 48">
                                                    <polygon fill="#151515" points="41,6 9.929,42 6.215,42 37.287,6"></polygon><polygon fill="#fff" fillRule="evenodd" points="31.143,41 7.82,7 16.777,7 40.1,41" clipRule="evenodd"></polygon><path fill="#151515" d="M15.724,9l20.578,30h-4.106L11.618,9H15.724 M17.304,6H5.922l24.694,36h11.382L17.304,6L17.304,6z"></path>
                                                </svg></a>
                                            </li>
                                            <li>
                                                <a href="/#"><svg xmlns="http://www.w3.org/2000/svg" className='social-icon' fill="#151515" width="20" height="20" viewBox="0 0 64 64">
                                                    <path d="M48,8H16c-4.418,0-8,3.582-8,8v32c0,4.418,3.582,8,8,8h32c4.418,0,8-3.582,8-8V16C56,11.582,52.418,8,48,8z M24,47h-5V27h5 V47z M24.029,23.009C23.382,23.669,22.538,24,21.5,24c-1.026,0-1.865-0.341-2.519-1.023S18,21.458,18,20.468 c0-1.02,0.327-1.852,0.981-2.498C19.635,17.323,20.474,17,21.5,17c1.038,0,1.882,0.323,2.529,0.969 C24.676,18.615,25,19.448,25,20.468C25,21.502,24.676,22.349,24.029,23.009z M47,47h-5V35.887C42,32.788,40.214,31,38,31 c-1.067,0-2.274,0.648-2.965,1.469S34,34.331,34,35.594V47h-5V27h5v3.164h0.078c1.472-2.435,3.613-3.644,6.426-3.652 C44.5,26.5,47,29.5,47,34.754V47z"></path>
                                                </svg></a>
                                            </li>
                                            <li>
                                                <a href="/#"><svg xmlns="http://www.w3.org/2000/svg" className='social-icon' fill="#151515" width="20" height="20" viewBox="0 0 48 48">
                                                    <circle cx="24" cy="24" r="20" fill="#151515"></circle><path fill="#FFF" d="M24.4439087,11.4161377c-8.6323242,0-13.2153931,5.7946167-13.2153931,12.1030884	c0,2.9338379,1.5615234,6.5853882,4.0599976,7.7484131c0.378418,0.1762085,0.581543,0.1000366,0.668457-0.2669067	c0.0668945-0.2784424,0.4038086-1.6369019,0.5553589-2.2684326c0.0484619-0.2015381,0.0246582-0.3746338-0.1384277-0.5731201	c-0.8269653-1.0030518-1.4884644-2.8461304-1.4884644-4.5645752c0-4.4115601,3.3399658-8.6799927,9.0299683-8.6799927	c4.9130859,0,8.3530884,3.3484497,8.3530884,8.1369019c0,5.4099731-2.7322998,9.1584473-6.2869263,9.1584473	c-1.9630737,0-3.4330444-1.6238403-2.9615479-3.6153564c0.5654297-2.3769531,1.6569214-4.9415283,1.6569214-6.6584473	c0-1.5354004-0.8230591-2.8169556-2.5299683-2.8169556c-2.006958,0-3.6184692,2.0753784-3.6184692,4.8569336	c0,1.7700195,0.5984497,2.9684448,0.5984497,2.9684448s-1.9822998,8.3815308-2.3453979,9.9415283	c-0.4019775,1.72229-0.2453003,4.1416016-0.0713501,5.7233887l0,0c0.4511108,0.1768799,0.9024048,0.3537598,1.3687744,0.4981079l0,0	c0.8168945-1.3278198,2.0349731-3.5056763,2.4864502-5.2422485c0.2438354-0.9361572,1.2468872-4.7546387,1.2468872-4.7546387	c0.6515503,1.2438965,2.5561523,2.296936,4.5831299,2.296936c6.0314941,0,10.378418-5.546936,10.378418-12.4400024	C36.7738647,16.3591919,31.3823242,11.4161377,24.4439087,11.4161377z"></path>
                                                </svg></a>
                                            </li>
                                            <li>
                                                <a href="/#"><svg xmlns="http://www.w3.org/2000/svg" className='social-icon' fill="#151515" width="20" height="20" viewBox="0 0 48 48">
                                                    <path d="M 15 4 C 8.9365932 4 4 8.9365932 4 15 L 4 33 C 4 39.063407 8.9365932 44 15 44 L 33 44 C 39.063407 44 44 39.063407 44 33 L 44 15 C 44 8.9365932 39.063407 4 33 4 L 15 4 z M 15 6 L 33 6 C 37.982593 6 42 10.017407 42 15 L 42 33 C 42 37.982593 37.982593 42 33 42 L 15 42 C 10.017407 42 6 37.982593 6 33 L 6 15 C 6 10.017407 10.017407 6 15 6 z M 35 11 C 33.895 11 33 11.895 33 13 C 33 14.105 33.895 15 35 15 C 36.105 15 37 14.105 37 13 C 37 11.895 36.105 11 35 11 z M 24 14 C 18.488666 14 14 18.488666 14 24 C 14 29.511334 18.488666 34 24 34 C 29.511334 34 34 29.511334 34 24 C 34 18.488666 29.511334 14 24 14 z M 24 16 C 28.430666 16 32 19.569334 32 24 C 32 28.430666 28.430666 32 24 32 C 19.569334 32 16 28.430666 16 24 C 16 19.569334 19.569334 16 24 16 z"></path>
                                                </svg></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                <div className="team-box">
                                    <div className="upper-part">
                                        <figure className="mb-0">
                                            <img src={require("../assets/images/team-image2.png")} alt="" className="img-fluid" />
                                        </figure>
                                    </div>
                                    <div className="lower-part">
                                        <h3>Bhargavi Darji</h3>
                                        <p>CTO</p>
                                        <ul className="list-unstyled mb-0">
                                            <li>
                                                <a href="/#"><svg xmlns="http://www.w3.org/2000/svg" className='social-icon' fill="#151515" width="20" height="20" viewBox="0 0 64 64">
                                                    <path d="M32,6C17.642,6,6,17.642,6,32c0,13.035,9.603,23.799,22.113,25.679V38.89H21.68v-6.834h6.433v-4.548	c0-7.529,3.668-10.833,9.926-10.833c2.996,0,4.583,0.223,5.332,0.323v5.965h-4.268c-2.656,0-3.584,2.52-3.584,5.358v3.735h7.785	l-1.055,6.834h-6.73v18.843C48.209,56.013,58,45.163,58,32C58,17.642,46.359,6,32,6z"></path>
                                                </svg></a>
                                            </li>
                                            <li>
                                                <a href="/#"><svg xmlns="http://www.w3.org/2000/svg" className='social-icon' fill="#151515" width="20" height="20" viewBox="0 0 48 48">
                                                    <polygon fill="#151515" points="41,6 9.929,42 6.215,42 37.287,6"></polygon><polygon fill="#fff" fillRule="evenodd" points="31.143,41 7.82,7 16.777,7 40.1,41" clipRule="evenodd"></polygon><path fill="#151515" d="M15.724,9l20.578,30h-4.106L11.618,9H15.724 M17.304,6H5.922l24.694,36h11.382L17.304,6L17.304,6z"></path>
                                                </svg></a>
                                            </li>
                                            <li>
                                                <a href="/#"><svg xmlns="http://www.w3.org/2000/svg" className='social-icon' fill="#151515" width="20" height="20" viewBox="0 0 64 64">
                                                    <path d="M48,8H16c-4.418,0-8,3.582-8,8v32c0,4.418,3.582,8,8,8h32c4.418,0,8-3.582,8-8V16C56,11.582,52.418,8,48,8z M24,47h-5V27h5 V47z M24.029,23.009C23.382,23.669,22.538,24,21.5,24c-1.026,0-1.865-0.341-2.519-1.023S18,21.458,18,20.468 c0-1.02,0.327-1.852,0.981-2.498C19.635,17.323,20.474,17,21.5,17c1.038,0,1.882,0.323,2.529,0.969 C24.676,18.615,25,19.448,25,20.468C25,21.502,24.676,22.349,24.029,23.009z M47,47h-5V35.887C42,32.788,40.214,31,38,31 c-1.067,0-2.274,0.648-2.965,1.469S34,34.331,34,35.594V47h-5V27h5v3.164h0.078c1.472-2.435,3.613-3.644,6.426-3.652 C44.5,26.5,47,29.5,47,34.754V47z"></path>
                                                </svg></a>
                                            </li>
                                            <li>
                                                <a href="/#"><svg xmlns="http://www.w3.org/2000/svg" className='social-icon' fill="#151515" width="20" height="20" viewBox="0 0 48 48">
                                                    <circle cx="24" cy="24" r="20" fill="#151515"></circle><path fill="#FFF" d="M24.4439087,11.4161377c-8.6323242,0-13.2153931,5.7946167-13.2153931,12.1030884	c0,2.9338379,1.5615234,6.5853882,4.0599976,7.7484131c0.378418,0.1762085,0.581543,0.1000366,0.668457-0.2669067	c0.0668945-0.2784424,0.4038086-1.6369019,0.5553589-2.2684326c0.0484619-0.2015381,0.0246582-0.3746338-0.1384277-0.5731201	c-0.8269653-1.0030518-1.4884644-2.8461304-1.4884644-4.5645752c0-4.4115601,3.3399658-8.6799927,9.0299683-8.6799927	c4.9130859,0,8.3530884,3.3484497,8.3530884,8.1369019c0,5.4099731-2.7322998,9.1584473-6.2869263,9.1584473	c-1.9630737,0-3.4330444-1.6238403-2.9615479-3.6153564c0.5654297-2.3769531,1.6569214-4.9415283,1.6569214-6.6584473	c0-1.5354004-0.8230591-2.8169556-2.5299683-2.8169556c-2.006958,0-3.6184692,2.0753784-3.6184692,4.8569336	c0,1.7700195,0.5984497,2.9684448,0.5984497,2.9684448s-1.9822998,8.3815308-2.3453979,9.9415283	c-0.4019775,1.72229-0.2453003,4.1416016-0.0713501,5.7233887l0,0c0.4511108,0.1768799,0.9024048,0.3537598,1.3687744,0.4981079l0,0	c0.8168945-1.3278198,2.0349731-3.5056763,2.4864502-5.2422485c0.2438354-0.9361572,1.2468872-4.7546387,1.2468872-4.7546387	c0.6515503,1.2438965,2.5561523,2.296936,4.5831299,2.296936c6.0314941,0,10.378418-5.546936,10.378418-12.4400024	C36.7738647,16.3591919,31.3823242,11.4161377,24.4439087,11.4161377z"></path>
                                                </svg></a>
                                            </li>
                                            <li>
                                                <a href="/#"><svg xmlns="http://www.w3.org/2000/svg" className='social-icon' fill="#151515" width="20" height="20" viewBox="0 0 48 48">
                                                    <path d="M 15 4 C 8.9365932 4 4 8.9365932 4 15 L 4 33 C 4 39.063407 8.9365932 44 15 44 L 33 44 C 39.063407 44 44 39.063407 44 33 L 44 15 C 44 8.9365932 39.063407 4 33 4 L 15 4 z M 15 6 L 33 6 C 37.982593 6 42 10.017407 42 15 L 42 33 C 42 37.982593 37.982593 42 33 42 L 15 42 C 10.017407 42 6 37.982593 6 33 L 6 15 C 6 10.017407 10.017407 6 15 6 z M 35 11 C 33.895 11 33 11.895 33 13 C 33 14.105 33.895 15 35 15 C 36.105 15 37 14.105 37 13 C 37 11.895 36.105 11 35 11 z M 24 14 C 18.488666 14 14 18.488666 14 24 C 14 29.511334 18.488666 34 24 34 C 29.511334 34 34 29.511334 34 24 C 34 18.488666 29.511334 14 24 14 z M 24 16 C 28.430666 16 32 19.569334 32 24 C 32 28.430666 28.430666 32 24 32 C 19.569334 32 16 28.430666 16 24 C 16 19.569334 19.569334 16 24 16 z"></path>
                                                </svg></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                <div className="team-box">
                                    <div className="upper-part">
                                        <figure className="mb-0">
                                            <img src={require("../assets/images/team-image3.png")} alt="" className="img-fluid" />
                                        </figure>
                                    </div>
                                    <div className="lower-part">
                                        <h3>Arjun Makadiya</h3>
                                        <p>CMO</p>
                                        <ul className="list-unstyled mb-0">
                                            <li>
                                                <a href="/#"><svg xmlns="http://www.w3.org/2000/svg" className='social-icon' fill="#151515" width="20" height="20" viewBox="0 0 64 64">
                                                    <path d="M32,6C17.642,6,6,17.642,6,32c0,13.035,9.603,23.799,22.113,25.679V38.89H21.68v-6.834h6.433v-4.548	c0-7.529,3.668-10.833,9.926-10.833c2.996,0,4.583,0.223,5.332,0.323v5.965h-4.268c-2.656,0-3.584,2.52-3.584,5.358v3.735h7.785	l-1.055,6.834h-6.73v18.843C48.209,56.013,58,45.163,58,32C58,17.642,46.359,6,32,6z"></path>
                                                </svg></a>
                                            </li>
                                            <li>
                                                <a href="/#"><svg xmlns="http://www.w3.org/2000/svg" className='social-icon' fill="#151515" width="20" height="20" viewBox="0 0 48 48">
                                                    <polygon fill="#151515" points="41,6 9.929,42 6.215,42 37.287,6"></polygon><polygon fill="#fff" fillRule="evenodd" points="31.143,41 7.82,7 16.777,7 40.1,41" clipRule="evenodd"></polygon><path fill="#151515" d="M15.724,9l20.578,30h-4.106L11.618,9H15.724 M17.304,6H5.922l24.694,36h11.382L17.304,6L17.304,6z"></path>
                                                </svg></a>
                                            </li>
                                            <li>
                                                <a href="/#"><svg xmlns="http://www.w3.org/2000/svg" className='social-icon' fill="#151515" width="20" height="20" viewBox="0 0 64 64">
                                                    <path d="M48,8H16c-4.418,0-8,3.582-8,8v32c0,4.418,3.582,8,8,8h32c4.418,0,8-3.582,8-8V16C56,11.582,52.418,8,48,8z M24,47h-5V27h5 V47z M24.029,23.009C23.382,23.669,22.538,24,21.5,24c-1.026,0-1.865-0.341-2.519-1.023S18,21.458,18,20.468 c0-1.02,0.327-1.852,0.981-2.498C19.635,17.323,20.474,17,21.5,17c1.038,0,1.882,0.323,2.529,0.969 C24.676,18.615,25,19.448,25,20.468C25,21.502,24.676,22.349,24.029,23.009z M47,47h-5V35.887C42,32.788,40.214,31,38,31 c-1.067,0-2.274,0.648-2.965,1.469S34,34.331,34,35.594V47h-5V27h5v3.164h0.078c1.472-2.435,3.613-3.644,6.426-3.652 C44.5,26.5,47,29.5,47,34.754V47z"></path>
                                                </svg></a>
                                            </li>
                                            <li>
                                                <a href="/#"><svg xmlns="http://www.w3.org/2000/svg" className='social-icon' fill="#151515" width="20" height="20" viewBox="0 0 48 48">
                                                    <circle cx="24" cy="24" r="20" fill="#151515"></circle><path fill="#FFF" d="M24.4439087,11.4161377c-8.6323242,0-13.2153931,5.7946167-13.2153931,12.1030884	c0,2.9338379,1.5615234,6.5853882,4.0599976,7.7484131c0.378418,0.1762085,0.581543,0.1000366,0.668457-0.2669067	c0.0668945-0.2784424,0.4038086-1.6369019,0.5553589-2.2684326c0.0484619-0.2015381,0.0246582-0.3746338-0.1384277-0.5731201	c-0.8269653-1.0030518-1.4884644-2.8461304-1.4884644-4.5645752c0-4.4115601,3.3399658-8.6799927,9.0299683-8.6799927	c4.9130859,0,8.3530884,3.3484497,8.3530884,8.1369019c0,5.4099731-2.7322998,9.1584473-6.2869263,9.1584473	c-1.9630737,0-3.4330444-1.6238403-2.9615479-3.6153564c0.5654297-2.3769531,1.6569214-4.9415283,1.6569214-6.6584473	c0-1.5354004-0.8230591-2.8169556-2.5299683-2.8169556c-2.006958,0-3.6184692,2.0753784-3.6184692,4.8569336	c0,1.7700195,0.5984497,2.9684448,0.5984497,2.9684448s-1.9822998,8.3815308-2.3453979,9.9415283	c-0.4019775,1.72229-0.2453003,4.1416016-0.0713501,5.7233887l0,0c0.4511108,0.1768799,0.9024048,0.3537598,1.3687744,0.4981079l0,0	c0.8168945-1.3278198,2.0349731-3.5056763,2.4864502-5.2422485c0.2438354-0.9361572,1.2468872-4.7546387,1.2468872-4.7546387	c0.6515503,1.2438965,2.5561523,2.296936,4.5831299,2.296936c6.0314941,0,10.378418-5.546936,10.378418-12.4400024	C36.7738647,16.3591919,31.3823242,11.4161377,24.4439087,11.4161377z"></path>
                                                </svg></a>
                                            </li>
                                            <li>
                                                <a href="/#"><svg xmlns="http://www.w3.org/2000/svg" className='social-icon' fill="#151515" width="20" height="20" viewBox="0 0 48 48">
                                                    <path d="M 15 4 C 8.9365932 4 4 8.9365932 4 15 L 4 33 C 4 39.063407 8.9365932 44 15 44 L 33 44 C 39.063407 44 44 39.063407 44 33 L 44 15 C 44 8.9365932 39.063407 4 33 4 L 15 4 z M 15 6 L 33 6 C 37.982593 6 42 10.017407 42 15 L 42 33 C 42 37.982593 37.982593 42 33 42 L 15 42 C 10.017407 42 6 37.982593 6 33 L 6 15 C 6 10.017407 10.017407 6 15 6 z M 35 11 C 33.895 11 33 11.895 33 13 C 33 14.105 33.895 15 35 15 C 36.105 15 37 14.105 37 13 C 37 11.895 36.105 11 35 11 z M 24 14 C 18.488666 14 14 18.488666 14 24 C 14 29.511334 18.488666 34 24 34 C 29.511334 34 34 29.511334 34 24 C 34 18.488666 29.511334 14 24 14 z M 24 16 C 28.430666 16 32 19.569334 32 24 C 32 28.430666 28.430666 32 24 32 C 19.569334 32 16 28.430666 16 24 C 16 19.569334 19.569334 16 24 16 z"></path>
                                                </svg></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*-- Reviews --*/}



        </>

    );

}

export default About;
