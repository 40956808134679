import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

function Faq() {


  return (
    <>

      <div className="sub-banner">
        <section className="banner-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="banner_content" data-aos="fade-up">
                  <div className="box">
                    <span className="mb-0">Home</span>
                    <figure className="mb-0">
                      <img src={require("../assets/images/subbanner_arrow.png")} alt="" className="img-fluid" />
                    </figure>
                    <span className="mb-0 box_span">Faq</span>
                  </div>
                  <h1>Faq’s</h1>
                  <p className="mb-0 text-white">Sed quia non numquam eius modi tempora incidunt ut labore et magnam aliquam quaerat voluptatem nostrum.</p>
                </div>
              </div>
            </div>
            <figure className="mb-0 bannersidecircle1" data-aos="fade-left">
              <img src="./assets/images/banner-sidecircle1.png" alt="" />
            </figure>
            <figure className="mb-0 bannersidecircle2" data-aos="fade-left">
              <img src="./assets/images/banner-sidecircle2.png" alt="" />
            </figure>
          </div>
        </section>
      </div>


      {/*-- FAQ's --*/}


      <section className="accordian-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="accordian_content">
                <h4>Ask Something?</h4>
                <h2>Frequently Asked Questions</h2>
              </div>
            </div>
          </div>
          <div className="row position-relative" data-aos="fade-up">
            <div className="col-lg-1 col-md-1 col-sm-1 d-lg-block d-none"></div>
            <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12">


              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Itaque earum Maiores alias conseuatur aut peruerendis?
                  </Accordion.Header>
                  <Accordion.Body>
                    There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which do not look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header>Alias conseuatur Dolor renellendus temporibus autem zuibusdam?
                  </Accordion.Header>
                  <Accordion.Body>
                    consectetur adipiscing elit, Lorem ipsum dolor sit amet, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>Temporibus autem officia deserunt mollitia animi est laborum?
                  </Accordion.Header>
                  <Accordion.Body>
                    Dolore magnam incidunt ut labore et aliquam quaerat voluptatem ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>Animi est reiciendis voluptatibus maiores alias consequatur?
                  </Accordion.Header>
                  <Accordion.Body>
                    Aliquam quaerat voluptatem incidunt ut labore et dolore magnam ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi.
                  </Accordion.Body>
                </Accordion.Item>


              </Accordion>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-1 d-lg-block d-none"></div>
            <figure className="mb-0 faq_shape">
              <img src={require("../assets/images/faq_icon.png")} alt="" className="img-fluid" />
            </figure>
          </div>
        </div>
      </section>


    </>

  );

}

export default Faq;
