import Home from "./components/Home";
import Features from "./components/Features";
import Faq from "./components/Faq";
import Pricing from "./components/Pricing";
import About from "./components/About";
import Contact from "./components/Contact";


const dashboardRoutes = [

  {
    path: "/home",
    name: "Home",
    icon: "nc-icon nc-notes",
    component: Home,
	className:"nav-link",
    layout: "/"
  },
 
  {
    path: "/features",
    name: "Features",
    icon: "nc-icon nc-favourite-28",
    component: Features,
	className:"nav-link",
    layout: "/"
  }, 
  
  {
    path: "/faq",
    name: "Faq",
    icon: "nc-icon nc-refresh-02",
    component: Faq,
	className:"nav-link",
    layout: "/"
  },  

  {
    path: "/pricing",
    name: "Pricing",
    icon: "nc-icon nc-check-2",
    component: Pricing,
	className:"nav-link",
    layout: "/"
  },
  
   {
    path: "/about",
    name: "About",
    icon: "nc-icon nc-check-2",
    component: About,
	className:"nav-link",
    layout: "/"
  },
   {
    path: "/contact",
    name: "Try For Free",
    icon: "nc-icon nc-check-2",
    component: Contact,
	className:"nav-link try_free_btn",
    layout: "/"
  },

];

export default dashboardRoutes;
