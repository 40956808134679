import React, {useState} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import {Modal} from "react-bootstrap";


function Home() {

const [showModal, setShowModal] = useState(false);

  return (
	<>

    <div className="banner_outer">
		<section className="banner-section">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 col-md-6 col-sm-12 col-12">
						<div className="banner_content" data-aos="fade-down">
							<h1>Creating stellar app prototypes to empower startup success.</h1>
							<h3 className="text-white">Do More, Go Upper</h3>
							<p className="text-white">Increase productivity with a simple to-do app</p>
							<span >Available on:</span>
							<div className="image_wrapper">
								<a href="https://www.apple.com/app-store/">
									<figure className="mb-0 image_apple">
										<img className="img-fluid" src={require("../assets/images/bannerapple-img1.png")} alt="" />
									</figure>
								</a>
								<a className="image_google" href="https://play.google.com/store/games">
									<figure className="mb-0 image_google"> 
										<img className="img-fluid" src={require("../assets/images/bannergoogle-img2.png")}  alt=""/>
									</figure> 
								</a>   
							</div> 
						</div>
					</div>
					<div className="col-lg-6 col-md-6 col-sm-12 col-12">
						<div className="banner_wrapper2">
							<figure className="mb-0 bannerphoneback">
								<img src={require("../assets/images/bannerphoneback-image1.png")} alt=""/>
							</figure>
							<figure className="mb-0 bannerphone2" >
								<img className="img-fluid" src={require("../assets/images/mobile-design-1.png")} 
								alt=""/>
							</figure>
							<figure className="mb-0 bannerphone-circleicon">
								<img src={require("../assets/images/bannerphone-circleicon.png")} alt=""/>
							</figure> 
							<figure className="mb-0 bannerphone-circle">
								<img className="img-fluid" src={require("../assets/images/bannerphone-circle.png")} alt=""/>
							</figure>
							<figure className="mb-0 bannerphone1">
								<img src={require("../assets/images/mobile-design.png")} alt=""/>
							</figure> 
						</div>
					</div>
				</div>
				<figure className="mb-0 bannersidecircle1" data-aos="fade-left">
					<img src={require("../assets/images/banner-sidecircle1.png")} alt=""/>
				</figure>
				<figure className="mb-0 bannersidecircle2" data-aos="fade-left">
					<img src={require("../assets/images/banner-sidecircle2.png")} alt=""/>
				</figure>
			</div>    
		</section>
	</div>	



<div className="partner-section"> 
    <div className="container">
        <div className="partner_box" data-aos="fade-up">
            <ul className="mb-0 list-unstyled">
                <li>
                    <figure className="mb-0 partner1">
                        <img className="img-fluid" src={require("../assets/images/partner1.png")} alt="" />
                    </figure>
                </li>
                <li>
                    <figure className="mb-0 partner1 partner2">
                        <img className="img-fluid" src={require("../assets/images/partner2.png")} alt="" />
                    </figure>
                </li>
                <li>
                    <figure className="mb-0 partner1 partner3">
                        <img className="img-fluid" src={require("../assets/images/partner3.png")} alt="" />
                    </figure>
                </li>
                <li>
                    <figure className="mb-0 partner1 partner4">
                        <img className="img-fluid" src={require("../assets/images/partner4.png")} alt="" />
                    </figure>
                </li>
                <li>
                    <figure className="mb-0 partner1 partner5">
                        <img className="img-fluid" src={require("../assets/images/partner5.png")} alt="" />
                    </figure>
                </li>
            </ul>
        </div>
    </div>
</div>	

{/*-- About Us --*/}

<section className="aboutus-section overflow-hidden">
    <div className="container">
        <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                <div className="aboutus_content">
                    <h4>About Us</h4>
                    <h2 className="mb-0 ">Why Should You Choose Us</h2>
                </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                <div className="aboutus_text" data-aos="fade-left">
                    <p className="mb-0 ">100% Satisfaction is our goal, where our services and solutions have you wanting us back month after month. Our process makes sure you and your employees will be satisfied.</p>
                </div>
            </div>
        </div>
    </div>
</section>

{/*-- Features --*/}

<section className="basic-feature" id="basic-feature">
    <div className="container">
        <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="feature_wrapperleft">
                    <figure className="mb-0 feature-circle">
                        <img className="img-fluid" src={require("../assets/images/feature-circle.png")} alt="" />
                    </figure>
                    <figure className="mb-0 featurephoneback">
                        <img src={require("../assets/images/featurephoneback-img.png")} alt="" />
                    </figure>
                    <figure className="mb-0 featurephone">
                        <img className="img-fluid" src={require("../assets/images/featurephone-img.png")} alt="" />
                    </figure>
                    <figure className="mb-0 featurephone-over">
                        <img className="img-fluid" src={require("../assets/images/featurephone-over.png")} alt="" />
                    </figure>
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="feature_wrapperright">
                    <div className="feature_content">
                        <h4>Our Basic Features</h4>
                        <h2>Some of The Best Features</h2>
                    </div>
                    <div className="feature_lowercontent" data-aos="fade-up">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="feature-box">
                                    <figure className="feature-marketicon">
                                        <img src={require("../assets/images/feature-marketicon.png")} alt="" className="img-fluid" />
                                    </figure> 
                                    <h6>Marketing</h6>
                                    <p className="text-size-18 mb-0">We denounce with righteous indignation and dislike men who are so beguiled.</p>
									 
                                </div>   
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="feature-design">
                                    <div className="feature-box">
                                        <figure className="feature-graphicon feature-box-2">
                                            <img src={require("../assets/images/feature-graphicon.png")}  alt="" className="img-fluid" />
                                        </figure>
                                        <h6>Graphic Design</h6>
                                        <p className="text-size-18 mb-0">These cases are perfectly simple distinguish and easy to In a free hour.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="feature-downcontent">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="feature-box">
                                        <figure className="feature-graphicon feature-box-3">
                                            <img src={require("../assets/images/feature-supporticon.png")} alt="" className="img-fluid" />
                                        </figure>
                                        <h6>Support</h6>
                                        <p className="text-size-18 mb-0">Ruis aute irure dolor in re derit in voluptate velit esse fugiat nulla pariatur.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="feature-design feature-guide">
                                        <div className="feature-box">
                                            <figure className="feature-guideicon feature-box-4">
                                                <img src={require("../assets/images/feature-guideicon.png")} alt="" className="img-fluid" />
                                            </figure>
                                            <h6>User Guide</h6>
                                            <p className="text-size-18 mb-0">Auis aute irure dolor in re derit in voluptate velit esse fugiat nulla pariatur.</p> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

{/*-- offer-video --*/}

<div className="offer-videosection">
    <div className="container">
        <div className="row position-relative">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="position-relative">
                    <figure className="mb-0 vediosession">
                        <img className="thumb img-fluid" onClick={() =>setShowModal(true)} src={require("../assets/images/image-videosession.png")} alt="" />
                    </figure>
                </div>
            </div>
            <figure className="mb-0 image-sidecircle">
                <img className="img-fluid" src={require("../assets/images/image-sidecircle.png")} alt="" />
            </figure>

        </div>
    </div>
</div>

{/*-- offers --*/}

<section className="offer-section">
    <div className="container">
        <figure className="mb-0 offer-sidelayer">
            <img src={require("../assets/images/offer-sidelayer.png")} alt="" />
        </figure>
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="offer_content" data-aos="fade-down">
                    <h4 className="text-white">What We Offer</h4>
                    <h2 className="text-white">Fully Customized App For You!</h2>
                    <p className="text-white">Muis aute irure dolor in reprehenderit in voluptate velitesse cillum dolore eu fugia nulla pariatur excepteur sint occaecat cupidatat non proident exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi aonsequatur neprehenderit.</p>
                    <div className="offer-imagewrapper">
                        <a className="image_apple" href="https://www.apple.com/app-store/">
                            <figure className="mb-0 offer-apple">
                                <img className="img-fluid" src={require("../assets/images/bannerapple-img1.png")} alt="" />
                            </figure>
                        </a>
                        <a className="image_google" href="https://play.google.com/store/games">
                            <figure className="mb-0 offer-google"> 
                                <img className="img-fluid" src={require("../assets/images/bannergoogle-img2.png")} alt="" />
                            </figure> 
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

{/*-- Ease of Search --*/}

<section className="search-section">
    <div className="container">
        <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="search_content" data-aos="fade-up">
                    <figure className="search-icon">
                        <img src={require("../assets/images/search-icon.png")} alt="" className="img-fluid" />
                    </figure>
                    <h2>Ease Of Search</h2>
                    <p>Cum soluta nobis est eligendi optio cumque nam libero tempore, nihil impedit quo minus id quod max me placeat facere possimus omnis.</p>
                    <p className="p-text">Omnis dolor assumenda est, repellendus temoriu autem quibusdam et aut officiis repudiandae sint molestiae non recusandae. </p>
                    <div className="search-button">
                        <a className="try_free_btn" href="/contact">Try For Free</a>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="search_wrapper">
                    <figure className="mb-0 search-sidecircle">
                        <img className="img-fluid" src={require("../assets/images/search-sidecircle.png")} alt="" />
                    </figure>
                    <figure className="mb-0 search-phoneback">
                        <img src={require("../assets/images/search-phoneback.png")} alt="" />
                    </figure>
                    <figure className="mb-0 search-phone1">
                        <img className="img-fluid" src={require("../assets/images/search-phone1.png")} alt="" />
                    </figure>
                    <figure className="mb-0 search-phone2">
                        <img className="img-fluid" src={require("../assets/images/search-phone2.png")} alt="" />
                    </figure>
                </div>
            </div>
        </div>

        <div className="manage-section">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 order-md-1 order-2">
                   <div className="manage_wrapper">
                        <figure className="mb-0 manage-sidecircle">
                            <img className="img-fluid" src={require("../assets/images/manage-sidecircle.png")} alt="" />
                        </figure>
                        <figure className="mb-0 manage-phoneback">
                            <img src={require("../assets/images/manage-phoneback.png")} alt=""/>
                        </figure>
                        <figure className="mb-0 manage-phone2">
                            {/* <img className="img-fluid" src={require("../assets/images/manage-phone2.png")} alt="" /> */}
                        </figure>
                        <figure className="mb-0 manage-phone1">
                            <img className="img-fluid" src={require("../assets/images/mobile-design-2.png")} alt="" />
                        </figure>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 order-md-2 order-1">
                    <div className="manage_content" data-aos="fade-up">
                        <figure className="search-icon manage-icon">
                            <img src={require("../assets/images/manage-icon.png")} alt="" className="img-fluid" />
                        </figure>
                        <h2>Manage Your Ad’s</h2>
                        <p>Cum soluta nobis est eligendi nam libero tempore, optio cumque nihil impedit quo minus id quod max me placeat facere possimus omnis.</p>
                        <p className="p-text">Repellendus assumenda est, omnis dolor temoriu autem quibusdam et aut officiis repudiandae sint molestiae non recusandae. </p>
                        <div className="manage-button">
                            <a className="try_free_btn" href="/contact">Try For Free</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

{/*-- statistics --*/}

<section className="statistics-section" id="demographics">
    <div className="container">
        <figure className="mb-0 statistics-sidelayer">
            <img src={require("../assets/images/statistics-sidelayer.png")} alt="" />
        </figure>
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="statistics_content">
                    <h4 className="text-white">App Information</h4>
                    <h2 className="text-white">Explore Our Demographics</h2>
                    <p className="text-white">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident.</p>
                </div>
            </div>
        </div>
        <div className="statistics-value" data-aos="fade-down">
            <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                    <div className="number1">
                        <span className="value1 counter">1210</span>
                        <sup className="mb-0 plus">+</sup>
                        <span className="text1">Clients</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                    <div className="number1">
                        <span className="value1 counter">2110</span>
                        <sup className="mb-0 plus">+</sup>
                        <span className="text1 text2">Beneficiaries</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                    <div className="number1">
                        <span className="value1 counter">1512</span>
                        <sup className="mb-0 plus">+</sup>
                        <span className="mb-0 text1 text3">Reviews</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                    <div className="number1 number4">
                        <span className="value1 counter">1282</span>
                        <sup className="mb-0 plus">+</sup>
                        <span className="mb-0 text1 text4">Downloads</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

{/*-- Pricing Plan --*/}

<section className="price-section">
    <div className="container">
        <figure className="mb-0 price-circle">
            <img className="img-fluid" src={require("../assets/images/price-circle.png")} alt="" />
        </figure>
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="price_heading">
                    <h4>What We Offer</h4>
                    <h2>Choose Best Pricing Plan</h2>
                </div>
            </div>
        </div>
        <div className="price-block">
            <div className="row" data-aos="fade-up">
                 <figure className="mb-0 price-sidecircle">
                    <img className="img-fluid" src={require("../assets/images/price-sidecircle.png")}  alt="" />
                </figure>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-md-0 mb-4">
                    <div className="price_content hover1">
                        <div className="icon-size1">
                            <figure className="mb-0 price-basicicon">
                                <img src={require("../assets/images/price-basicicon.png")} alt="" className="img-fluid" />
                            </figure>
                        </div>
                        <h5 className="mb-0">Copper</h5>
                        <span className="text1">$19.99/mo</span>
                        <ul className="list-unstyled mb-0">
                            <li className="text-size-18">Free Consultation</li>
                            <li className="text-size-18">2GB Storage</li>
                            <li className="text-size-18">No Free Download Images</li>
                            <li className="text-size-18">Monthly Reports</li>
                            <li className="for-space text-size-18">Working Time Support</li>
                        </ul>
                        <div className="price-button">
                            <a className="get_started basic" href="/pricing">Get Started</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-md-0 mb-4">
                    <div className="price_content price_content1 hover1">
                        <div className="icon-size1 icon-size2">
                            <figure className="mb-0  price-premiumicon">
                                <img src={require("../assets/images/price-premiumicon.png")} alt="" className="img-fluid" />
                            </figure>
                        </div>
                        <h5 className="mb-0">Silver</h5>
                        <span className="text1 text2">$49.99/mo</span>
                        <ul className="list-unstyled mb-0">
                            <li className="text-size-18">Free Consultation</li>
                            <li className="text-size-18">4GB Storage</li>
                            <li className="text-size-18">10 Free Download Images</li>
                            <li className="text-size-18">Weekly Reports</li>
                            <li className="for-space text-size-18">24/7 Full Support</li>
                        </ul>
                        <div className="price-button">
                            <a className="get_started premium" href="/pricing">Get Started</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="price_content price_content2 hover1">
                        <div className="icon-size1 icon-size3">
                            <figure className="mb-0  price-businessicon">
                                <img src={require("../assets/images/price-businessicon.png")} alt="" className="img-fluid" />
                            </figure>
                        </div>
                        <h5 className="mb-0">Gold</h5>
                        <span className="text1 text2">$99.99/mo</span>
                        <ul className="list-unstyled mb-0">
                            <li className="text-size-18">Free Consultation</li>
                            <li className="text-size-18">10GB Storage</li>
                            <li className="text-size-18">100 Free Download Images</li>
                            <li className="text-size-18">Weekly, Monthly Reports</li>
                            <li className="for-space text-size-18">24/7 Full Support</li>
                        </ul>
                        <div className="price-button">
                            <a className="get_started business" href="/pricing">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

{/*-- FAQ's --*/}

<section className="accordian-section">
    <div className="container">
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="accordian_content">
                    <h4>Ask Something?</h4>
                    <h2>Frequently Asked Questions</h2>
                </div>
            </div>
        </div>
        <div className="row position-relative" data-aos="fade-up">
            <div className="col-lg-1 col-md-1 col-sm-1 d-lg-block d-none"></div>
            <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12">
			

            <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Itaque earum Maiores alias conseuatur aut peruerendis?
              </Accordion.Header>
              <Accordion.Body>
                There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which do not look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.				
              </Accordion.Body>
            </Accordion.Item>
      
            <Accordion.Item eventKey="1">
              <Accordion.Header>Alias conseuatur Dolor renellendus temporibus autem zuibusdam?
              </Accordion.Header>
              <Accordion.Body>
                consectetur adipiscing elit, Lorem ipsum dolor sit amet, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
      
            <Accordion.Item eventKey="2">
              <Accordion.Header>Temporibus autem officia deserunt mollitia animi est laborum?
              </Accordion.Header>
              <Accordion.Body>
              Dolore magnam incidunt ut labore et aliquam quaerat voluptatem ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi.
              </Accordion.Body>
            </Accordion.Item>
            
            <Accordion.Item eventKey="3">
              <Accordion.Header>Animi est reiciendis voluptatibus maiores alias consequatur?
              </Accordion.Header>
              <Accordion.Body>
              Aliquam quaerat voluptatem incidunt ut labore et dolore magnam ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi.
              </Accordion.Body>
            </Accordion.Item>
      
            
      </Accordion>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-1 d-lg-block d-none"></div>
            <figure className="mb-0 faq_shape">
                <img src={require("../assets/images/faq_icon.png")} alt="" className="img-fluid" />
            </figure>
        </div>
    </div>
</section>

{/* Add Modal */}
	 <Modal
          className="modal modal-primary ci-model"
          show={showModal}
          onHide={() => setShowModal(false)}			  
        >
		<div className="video-popup">
			<div className="video-popup-close" onClick={() =>setShowModal(false)}>X</div>
			<iframe id="video-popup" width="100%" height="400" src="//www.youtube.com/embed/MMZvgc-tfO4?rel=0&enablejsapi=1" title="video-popup" ></iframe>
		</div>           
        </Modal>
{/* End Add Modal */}	

	</>
  );	
	
}

export default Home;
