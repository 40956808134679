import React, {useEffect} from "react";

import './App.css';
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import AOS from 'aos';
import 'aos/dist/aos.css';

import Layout from './layout/Index';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";


function App() {

   useEffect(() => {
    AOS.init({
    duration: 1000,
    offset: 120,
    easing: 'ease-in-out'
});
      }, [])	
	

  return (
        <>
          <Router>
             <Routes>				
				<Route path="/*" element={<Layout replace to="/*" />} />				
				<Route path="/" element={<Navigate replace to="/home" />} />
				
            </Routes>
          </Router>   

		  
        </>
  );
}

export default App;
