import React from 'react';
import { Routes, Route } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";

import routes from "../routes";



export default function Index() {

 const getRoutes = (routes) => {
	 
    return routes.map((prop, key) => { 
		  
        return (
          <Route  path={prop.path} element={<prop.component />}  key={key} />	
        );
      
    });
  };
	
  return (
	<div className="ci-main">  
		<Header routes={routes} />	
		 <Routes>{getRoutes(routes)}</Routes> 
		<Footer />			
	</div>
	
  )
}
